<template>
    <dropzone
        ref="dropzone"
        :id="id"
        :options="_options"
        :useCustomSlot="true"
        :include-styling="false"
        @vdropzone-canceled="onCancel"
        @vdropzone-sending="onSending"
        @vdropzone-processing="onProcessing"
        @vdropzone-success="onSuccess"
        @vdropzone-error="onError"
        @vdropzone-complete="onComplete"
        @vdropzone-files-added="onFilesAdded"
        @vdropzone-file-added="onFileAdded"
        @vdropzone-thumbnail="onThumbnail"
        @vdropzone-queue-complete="onQueueComplete"
        @vdropzone-upload-progress="onUploadProgress"
    >
        <slot />
    </dropzone>
</template>

<script>
import { merge } from 'utils/lodash';
import Dropzone from 'vue2-dropzone';
export default {
    components: {
        Dropzone,
    },
    props: {
        id: {
            type: String,
            default: 'dropzone',
        },
        url: {
            type: String,
            default: null,
        },
        accepted: {
            type: String,
            default: null,
        },
        options: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        defaults: {
            previewsContainer: false,
            maxThumbnailFilesize: 100,
            thumbnailWidth: 80,
            thumbnailHeight: 80,
            dictInvalidFileType: 'Uploading files of this extension is currently not supported',
            dictFileTooBig: 'The file you are uploading is too big, please reduce the size of your file',
        },
    }),
    computed: {
        _options() {
            return merge(this.options, this.defaults, {
                acceptedFiles: this.accepted,
                url: this.url,
                headers: this.headers,
            });
        },
        headers() {
            return {
                Accept: 'application/json',
            };
        },
    },
    methods: {
        process() {
            this.$refs.dropzone.processQueue();
        },
        removeAll() {
            this.$refs.dropzone.removeAllFiles();
        },
        setOption(key, value) {
            this.$refs.dropzone.setOption(key, value);
        },
        onComplete(response) {
            this.$emit('complete', response);
        },
        onSuccess(file, response) {
            this.$emit('success', file, response);
        },
        onError(file, message, xhr) {
            this.$emit('error', file, message, xhr);
        },
        onSending(file, xhr, formData) {
            let _send = xhr.send;
            xhr.send = () => {
                _send.call(xhr, file);
            };
            this.$emit('sending', file, xhr, formData);
        },
        onProcessing(file) {
            this.$emit('processing', file);
        },
        onCancel(file) {
            this.$emit('cancel', file);
        },
        onFilesAdded(file) {
            this.$emit('filesAdded', file);
        },
        onFileAdded(file) {
            this.$emit('added', file);
        },
        onQueueComplete() {
            this.$emit('queue-complete');
        },
        onUploadProgress(file, progress, bytesSent) {
            this.$emit('progress', file, progress, bytesSent);
        },
        onThumbnail(file, dataUrl) {
            this.$emit('thumbnail', file, dataUrl);
        },
    },
};
</script>
